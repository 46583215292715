<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row >
        <el-col :span="4">
          <el-select v-model="form.styleTypeId" placeholder="款式类型" clearable class="searchParam" size="medium">
            <el-option
                v-for="item in styleTypeIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-input v-model="form.clothesName" placeholder="婚纱名称" clearable class="searchParam" size="medium"></el-input>
        </el-col>
        <el-col :span="4">
          <el-select v-model="form.operationId" placeholder="操作类型" clearable class="searchParam" size="medium">
            <el-option
                v-for="item in operationIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-input v-model="form.empName" placeholder="经办人" clearable class="searchParam" size="medium"></el-input>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="search" size="medium">搜索</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <el-row style="margin-top: 15px;padding-left: 10px">
      <el-table
          :data="table.clothesOperationList"
          border
          v-loading="table.loading"
          max-height="650"
          :row-style="{height: '38px'}"
          :cell-style="{padding: '0'}"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          :default-sort="{prop: 'id', order: 'ascending'}"
          style="width: 99%">
        <el-table-column prop="clothesName" label="衣服名称" align="center"/>
        <el-table-column prop="createDate" label="操作时间" align="center"/>
        <el-table-column prop="operationName" label="操作" align="center"/>
        <el-table-column prop="localShopName" label="始发地" align="center"/>
        <el-table-column prop="localPositionName" label="始发区域" align="center"/>
        <el-table-column prop="empName" label="操作人" align="center"/>
        <el-table-column prop="shopName" label="接收地" align="center"/>
        <el-table-column prop="positionName" label="接收区域" align="center"/>
        <el-table-column prop="remark" label="备注" align="center"/>
      </el-table>
    </el-row>
    <el-row style="margin-top: 3px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-row>
  </div>
</template>

<script>

export default {
name: "operation-list",
  data() {
    return {
      form:{
        styleTypeId:"",
        clothesName:"",
        operationId: "",
        empName: "",
        tenantCrop: localStorage.getItem("tenantCrop"),
      },
      styleTypeIdArray:[],
      operationIdArray:[],
      table: {
        loading:false,
        clothesOperationList: [],
        page: 1,
        limit: 100,
        total: 0,
      },
    }
  },
  created() {
    this.queryClothesOperationList();
    this.queryStyleTypeIdsArr();
    this.queryOperationIdArray();
  },
  methods: {
    search() {
      this.table.page = 1;
      this.queryClothesOperationList();
    },
    //查询客户列表
    queryClothesOperationList: function () {
      this.$axios({
        method: "GET",
        url: "/clothesOperation/clothesOperationList",
        params: {
          page: this.table.page,
          limit: this.table.limit,
          styleTypeId:this.form.styleTypeId,
          clothesName:this.form.clothesName,
          operationId: this.form.operationId,
          empName: this.form.empName,
          tenantCrop: this.form.tenantCrop,
        }
      }).then(response => {
        this.table.total = response.data.data.total;
        this.table.clothesOperationList = response.data.data.list;
        this.table.loading=false;
      })
    },
    //查询款式类型
    queryStyleTypeIdsArr(){
      this.$selectUtils.queryStyleTypeIds().then(response=>{
        this.styleTypeIdArray=JSON.parse(response.data.data);
      })
    },
    //查询操作
    queryOperationIdArray(){
      this.$selectUtils.queryOperationIds().then(response=>{
        this.operationIdArray=JSON.parse(response.data.data);
      })
    },
    //更改每页长度
    handleSizeChange: function (val) {
      this.table.loading=true;
      this.table.limit = val;
      this.queryClothesOperationList();
    },
    handleCurrentChange(val) {
      this.table.loading=true;
      this.table.page = val
      this.queryClothesOperationList()
    },
  }
}
</script>

<style scoped>
.searchParam{
  width: 220px;
}
</style>